import { render, staticRenderFns } from "./download.vue?vue&type=template&id=24ded7a6&scoped=true&"
import script from "./download.vue?vue&type=script&lang=js&"
export * from "./download.vue?vue&type=script&lang=js&"
import style0 from "./download.vue?vue&type=style&index=0&id=24ded7a6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ded7a6",
  null
  
)

export default component.exports